import Vue from 'vue'
import VueRouter from 'vue-router'
/* import HomeView from '../views/HomeView.vue' */

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/loginform/loginForm.vue')
  },
  {
    path: '/sys',
    name: 'sys',
    component: () => import('../views/system/SystemDemo.vue')
  },
  {
    path: '/ele',
    name: 'ele',
    component: () => import('../views/element/ElementView.vue')
  },
  {
    path: '/',
    redirect: '/login' //重定向到sys页面
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

//在路由配置文件中添加全局前置守卫，检查用户是否已登录。
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('jwt'); // 从本地存储获取登录状态

  if (to.path !== '/login' && !isLoggedIn) {
    next('/login'); // 未登录，重定向到登录页面
  } else {
    next(); // 已登录，允许访问
  }
});

export default router
