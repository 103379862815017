<template>
  <div>
    <el-header class="header">
      <div id="icon">
        <img v-if="isAction" src="./assets/10001.png" @click="changed">
        <img v-if="!isAction" src="./assets/10002.png" @click="changed">
        <div>
          <h1 v-if="isAction">{{ message[0] }}</h1>
          <h1 v-if="!isAction">{{ message[1] }}</h1>
        </div>
      </div>
      <el-button v-if="showLogoutButton" type="danger" @click="logout">退出登录</el-button>
    </el-header>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isAction: true,
      message: ["Message 1", "Message 2"]
    };
  },
  computed: {
    showLogoutButton() {
      return this.$route.path !== '/login';
    }
  },
  methods: {
    changed() {
      this.isAction = !this.isAction;
    },
    logout() {
      localStorage.removeItem('jwt');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  color: white;
}

#icon {
  display: flex;
  align-items: center;
}

#icon img {
  height: 100px;
  margin-right: 50px;
}

#icon div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#icon h1 {
  margin: 0;
  font-size: 24px;
}
.router-fade-enter-active, .router-fade-leave-active {
  transition: opacity 0.5s;
}
.router-fade-enter, .router-fade-leave-to /* .router-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>
